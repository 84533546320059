
form{
    width:70%;
    margin:0 auto;
}
form label {
    font-size:1.5rem;
}
input, select, textarea{
    width:100%;
    padding: 1rem;
    margin-bottom: 5px;
    border:none;
    outline-color:#AD974F; 
    background-color:lightgray;
}
input[type="submit"]{
    width:initial;
    background-color:#AD974F;
    color:white;
    padding: 1rem 2rem;
    border-radius: .8rem;
    cursor: pointer;
    transition: background-color 650ms;
    border-radius: 0;
}

input[type="submit"]:hover{
    opacity: 0.6;
    background-color: #AD974F;
}
.alert-success{
    background-color: rgba(77, 175, 124, 1);
    text-align: center;
    width: 60%;
    margin-bottom: 1rem;
    margin: auto;
    padding: 1.2rem;
    font-size: 1.4rem;

}

.alert-danger{
    background-color: rgba(150, 40, 27, 1);
    text-align: center;
    width: 60%;
    margin-bottom: 1rem;
    margin: auto;
    padding: 1.2rem;
    font-size: 1.4rem;
}
.submit-button{
    margin-top: 1rem;
}
@media screen and (max-width: 600px) {
    form{
        width: 100%;
    }
}