@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
.menu-toggler{
    position: absolute;
    top: 5rem;
    right: 5rem;
    width: 3rem;
    height: 2.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    z-index:1500;
    transition: transform 650ms ease-in-out;
    
}

.bar{
    background: #AD974F;
    width: 100%;
    height: 4px;
    /* border-radius: .8rem; */
}

.bar.half{
    width: 50%;
}
.bar.start{
    transform-origin: right;
    transition: transform 650ms cubic-bezier(0.54,-0.81,0.57,0.57);
}
.bar.end{
    align-self: flex-end;
    transform-origin: left;
    transition: transform 650ms cubic-bezier(0.54,-0.81,0.57,0.57);
}

.top-nav{
    width: 100%;
    height: 100vh;
    position: fixed;
    top:-100vh;
    z-index: 50;
    background:rgba(27,27,27,.92);
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 100%;
    transition: all 650ms cubic-bezier(1,0,0,1);
}

.nav-list{
    list-style: none;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav-list li a{
    margin: 0 .5rem;
    text-decoration: none;
    font-family: 'Oswald', sans-serif
}
.nav-link{
    color: white;
    font-size: 2.5rem;
    padding: 1rem;
}

.nav-link:hover,
.nav-link:focus{
    background: #AD974F;
    -webkit-background-clip: text;
    -webkit-text-fill-color:transparent
}

.top-nav.open{
    top:0;
    border-radius: initial;
}
.menu-toggler.open{
    transform: rotate(-45deg);
}
.open .bar.start{
    transform: rotate(-450deg) translateX(.8rem);
}
.open .bar.end{
     transform: rotate(-450deg) translateX(-.8rem);
}
@media screen and (max-width:768px){
    .nav-link{
		font-size: 2.5rem;
	}
}

@media screen and (max-width: 768px){
    .nav-list li a{
        
    }
}

@media screen and (max-width: 600px) {
    .menu-toggler{
        top:2rem;
        right: 2rem;
    }
    .nav-link{
        font-size: 1.8rem;
    }
}

