@import url('https://fonts.googleapis.com/css2?family=Amiri:wght@700&family=Oswald&display=swap');
.landing {
  position: relative;
  /* overflow-x: hidden; */
}

.name-info {
  text-align: center;
}
.name-info-link {
  color: #ad974f;
  font-style: italic;
}
.name-info-link {
  border-bottom: 1px solid #ad974f;
}
.fa-external-link-square-alt {
  color: white;
}
.name-info h1 {
  font-size: 4.5rem;
  text-transform: uppercase;
  color: #ad974f;
  font-family: 'Amiri', serif;
  margin-bottom: -2rem;
}
.name-info {
  text-align: center;
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: scrollDown 1s 1 forwards;
}

.name-info p {
  margin-bottom: 1rem;
}

@keyframes scrollDown {
  100% {
    top: 50%;
  }
}
@media screen and (max-width: 600px) {
  .name-info h1 {
    font-size: 3.5rem;
  }
  .name-info p {
    font-size: 1.3rem;
  }
}
