.portfolio-item{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5rem;
}

.portfolio-item:last-child{
	margin-bottom: 0;
}

.portfolio-img{
	flex:1;
}
.portfolio-description{
	flex:1;
}

.portfolio-description h1{
	font-size: 3rem;
	font-weight: 300;
	margin:1rem 0;
}
.portfolio-description h6{
	font-size: 1.5rem;
	text-transform: uppercase;
	font-weight: 300;
	opacity: 0.3;
}

.portfolio-description .cta{
	display: inline-block;
	margin-top: 2.5rem;
	font-size: 1.5rem;
	text-transform: uppercase;
	color:#AD974F;
	transition: all 650ms;
}

.portfolio-description .cta:hover{
	opacity: .7;
}

.view-more-div{
	margin-top: 7rem !important;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.view-more-btn{
	padding: 1rem 3rem 1rem 3rem;
	border-radius: 0;
	border: 1px solid #AD974F;
	color: white;
	background: transparent;
	font-weight: bold;
	transition: all 0.2s ease-in;
	cursor: pointer;
	outline: none;
}

.view-more-btn:hover{
	color: #AD974F;
	border: 1px solid white;
}