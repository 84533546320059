.timeline ul{
	border-left: 4px solid #AD974F;
	/* border-radius: .8rem; */
	margin:0 auto;
	position: relative;
	padding:5rem;
	list-style: none;
	text-align: left;
	width:70%;
}
.timeline h1{
	font-size: 2rem;
	text-transform: uppercase;
	font-weight: 300;
	margin-bottom: 1rem;
	opacity: .3;
}

.timeline .date{
	border-bottom: 1px solid rgba(255,255,255,0.1);
	margin-bottom: 1rem;
	position: relative;
}
.timeline .date:last-of-type{
	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: none;
}
.timeline .date::before,
.timeline .date::after {
	position: absolute;
	display: block;
	top:50%;
	transform: translateY(-50%);
}

.timeline .date::before{
	content: attr(data-date);
	left: -21rem;
	text-align: right;
	min-width: 12rem;
    font-size: 1.5rem;
    color:#AD974F;
}

.timeline .date::after{
	content: '';
	/* box-shadow: 0 0 0 4px white; */
	/* border-radius: 100%; */
	left:-6.4rem;
	height: .3rem;
	width:2.5rem;
    background-color:white;
    opacity: 0.6;
}

@media screen and (max-width: 768px) {
	/* .timeline ul{
		margin:0 0 0 auto;
	} */
	.timeline .date::before{
		left: -20rem;
	}
	/* .timeline .date::after{
		left:-6rem;
	} */
}

@media screen and (max-width: 600px) {
	.timeline ul{
		padding: 2rem;
		margin: 0 0 0 auto;
	}
	.timeline h2{
		font-size: 1.6rem;
	}

	.timeline .date::before{
		font-size: 1.4rem;
	}
	.timeline .date::after{
		left:-3.5rem;
	}
	.timeline .date::before{
		left: -17rem;
	}
}