@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Amiri:wght@700&family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
*{
    margin:0;
    padding:0;
	box-sizing: border-box;
}
html{
    font-size: 10px;
    color:#eee;
	font-family: 'Abel', sans-serif;
}
body{
    background-color:#1B1B1B;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    color: white;
	overflow-x: hidden;
}

section {
    padding: 5rem 0;
}
a {
    text-decoration: none;
    color: #eee;
}
p {
    font-size: 1.65rem;
    font-weight: 300;
}

img {
    width: 100%;
}

.container{
    width: 90%;
    max-width: 120rem;
    height: 100%;
    margin: 0 auto;
    position: relative;
}

.section-heading{
	text-align: center;
	margin-bottom:10rem;
}

.section-heading h1{
	font-size: 3.5rem;
	color:rgba(255,255,255,.3);
	text-transform: uppercase;
	font-weight: 300;
	position: relative;
	margin-bottom:1rem;
}

.section-heading h1::before,
.section-heading h1::after{
	content: '';
	position: absolute;
	bottom: -.5rem;
    background-color: rgba(255,255,255,0.75);
    left: 50%;
	transform: translateX(-50%);
}

.section-heading h1::before{
	width:10rem;
    height:3px;
    left: 50%;
	transform: translateX(-50%);
}
.section-heading h1::after{
	width:1.5rem;
	height: 1.5rem;
	/* border-radius: 100%; */
    bottom:-1rem;
    transform:rotate(45deg);
    margin-left: -.5rem;
    background: #AD974F;
}
.section-heading h6{
	font-size: 1.6rem;
	font-weight: 300;

}

.has-margin-right{
	margin-right: 5rem;
}


/* -------------------------media-------------------------------------------------------- */
@media screen and (max-width:1100px){
	html{
		overflow-x: hidden;
	}
}


@media screen and (max-width: 768px) {
	html{
		overflow-x: hidden;
	}
	.name-info h1{
		font-size:12rem;
	}
	.name-info h6{
		font-size: 1.7rem;
	}
	.nav-list{
		flex-direction: column;
	}
	li{
		margin:2rem 0;
	}
	.nav-link{
		font-size: 4.5rem;
	}
	.about-heading h1{
		font-size: 8rem;
	}
	.section-heading h1{
		font-size: 3rem;
	}
	.about-details .nav-list{
		flex-direction: initial;
	}
	.about-details li{
		margin:0 1.5rem;
	}

	.icon-container{
		width:7rem;
		height: 7rem;
	}
	.icon-container i{
		font-size: 4rem;
	}
	.timeline ul{
		margin:0 0 0 auto;
	}
	.timeline .date::befor{
		left: -20rem;
	}
	.timeline .date::after{
		left:-6rem;
	}
	}

	@media screen and (max-width: 600px) {
        
		html{
			overflow-x: hidden;
        }
        
		.menu-toggler{
			top:2rem;
			right: 2rem;
		}

		.name-info h1 {
			font-size: 8rem;
		}
		.name-info h6{
			font-size: 1.4rem;
		}
		.nav-link{
			font-size: 3.5rem;
		}
		.about .container{
			flex-direction:column;
		}
		.profile-img{
			margin:0 0 7rem 0;
		}

		.portfolio-item{
			flex-direction: column;
		}

		.portfolio-img{
			margin: 0 0 2rem 0;
			order:1;
		}
		.portfolio-description{
			margin:0;
			order:2;
		}

		.timeline p{
			font-size: 1.6rem;
		}

		.timeline .date::before{
			font-size: 1.4rem;
		}

		form{
			width:100%;
		}
		.up{
			right: 2rem;
		}
	}

.landing {
  position: relative;
  /* overflow-x: hidden; */
}

.name-info {
  text-align: center;
}
.name-info-link {
  color: #ad974f;
  font-style: italic;
}
.name-info-link {
  border-bottom: 1px solid #ad974f;
}
.fa-external-link-square-alt {
  color: white;
}
.name-info h1 {
  font-size: 4.5rem;
  text-transform: uppercase;
  color: #ad974f;
  font-family: 'Amiri', serif;
  margin-bottom: -2rem;
}
.name-info {
  text-align: center;
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-animation: scrollDown 1s 1 forwards;
          animation: scrollDown 1s 1 forwards;
}

.name-info p {
  margin-bottom: 1rem;
}

@-webkit-keyframes scrollDown {
  100% {
    top: 50%;
  }
}

@keyframes scrollDown {
  100% {
    top: 50%;
  }
}
@media screen and (max-width: 600px) {
  .name-info h1 {
    font-size: 3.5rem;
  }
  .name-info p {
    font-size: 1.3rem;
  }
}

.menu-toggler{
    position: absolute;
    top: 5rem;
    right: 5rem;
    width: 3rem;
    height: 2.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    z-index:1500;
    transition: transform 650ms ease-in-out;
    
}

.bar{
    background: #AD974F;
    width: 100%;
    height: 4px;
    /* border-radius: .8rem; */
}

.bar.half{
    width: 50%;
}
.bar.start{
    transform-origin: right;
    transition: transform 650ms cubic-bezier(0.54,-0.81,0.57,0.57);
}
.bar.end{
    align-self: flex-end;
    transform-origin: left;
    transition: transform 650ms cubic-bezier(0.54,-0.81,0.57,0.57);
}

.top-nav{
    width: 100%;
    height: 100vh;
    position: fixed;
    top:-100vh;
    z-index: 50;
    background:rgba(27,27,27,.92);
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 100%;
    transition: all 650ms cubic-bezier(1,0,0,1);
}

.nav-list{
    list-style: none;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav-list li a{
    margin: 0 .5rem;
    text-decoration: none;
    font-family: 'Oswald', sans-serif
}
.nav-link{
    color: white;
    font-size: 2.5rem;
    padding: 1rem;
}

.nav-link:hover,
.nav-link:focus{
    background: #AD974F;
    -webkit-background-clip: text;
    -webkit-text-fill-color:transparent
}

.top-nav.open{
    top:0;
    border-radius: 0;
    border-radius: initial;
}
.menu-toggler.open{
    transform: rotate(-45deg);
}
.open .bar.start{
    transform: rotate(-450deg) translateX(.8rem);
}
.open .bar.end{
     transform: rotate(-450deg) translateX(-.8rem);
}
@media screen and (max-width:768px){
    .nav-link{
		font-size: 2.5rem;
	}
}

@media screen and (max-width: 768px){
    .nav-list li a{
        
    }
}

@media screen and (max-width: 600px) {
    .menu-toggler{
        top:2rem;
        right: 2rem;
    }
    .nav-link{
        font-size: 1.8rem;
    }
}


.about .container {
    display: flex;
	align-items: center;
    justify-content: center;
}

.about-heading{
    text-align: center;
	text-transform: uppercase;
	line-height:0px;
	margin-bottom:6rem;
}

.about-heading h1{
    font-size: 10rem;
    opacity: .3;
}

.about-heading h6{
    font-size: 2rem;
    font-weight: 300;
}
.nav-list {
	list-style: none;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
    align-items: center; 
}

.nav-list-custom li {
    margin: 0 !important;
}
.profile-img{
    flex:1 1;
    margin-right: 5rem;
}


.about-details {
    flex: 1 1;
}

.social-media{
    margin-top: 5rem;
}

.social-media i {
    font-size: 5rem;
    transition: color 650ms;
}

.fa-facebook-square:hover{
	color:#4267b2;
}
.fa-cloud-download-alt:hover{
	color:#38a1f3;
}
.fa-github-square:hover{
    color:#333;
}

@media screen and (max-width: 768px) {
    .about-heading h1{
        font-size: 8rem;
	}
	.section-heading h1{
		font-size: 3rem;
    }
    .about-details .nav-list{
		flex-direction: initial;
    }
    .about-details i{
        font-size: 4rem;
	}
}

@media screen and (max-width: 600px) {
    .profile-img{
        margin:0 0 7rem 0;
    }
    .portfolio-item{
        flex-direction: column;
    }

}
.my-skills{
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.skill{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 2rem; */
    margin-right: 2rem;

}

.skill h1{
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 2rem -2px 2rem 0;
}

.icon-container {
    width: 14rem;
    height: 14rem;
    border: 3px solid white;
    background-color: transparent;
    margin-bottom: 2rem;
    display: flex;
    transform:rotate(45deg);
    justify-content: center;
}

.icon-container i {
    font-size: 5rem;
    margin:auto;
    transform: rotate(-45deg);
    color: #AD974F;
}

.icon-container h1{
    align-self: center;
    transform: rotate(-45deg);
    color: #AD974F;
    font-size: 2.3rem;
    text-transform: none;
}

@media screen and (max-width: 600px) {
    .my-skills{
        flex-direction: column;
    }
    .icon-container i{
        font-size: 3rem;
    }
    .icon-container{
        height: 8rem;
        width: 8rem;
    }
    .skill h1{
        font-size: 1.3rem;
        margin-left: 2rem;
    }
    .icon-container{
        margin-top: .5rem;
        margin-bottom: .5rem;
        margin-left: .8rem;
    }

    .icon-container h1{
        font-size: 1.6rem;
        margin-bottom: 1rem;
        margin-right: 3rem;
        padding-left: 2rem;
    }
   
}
.portfolio-item{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5rem;
}

.portfolio-item:last-child{
	margin-bottom: 0;
}

.portfolio-img{
	flex:1 1;
}
.portfolio-description{
	flex:1 1;
}

.portfolio-description h1{
	font-size: 3rem;
	font-weight: 300;
	margin:1rem 0;
}
.portfolio-description h6{
	font-size: 1.5rem;
	text-transform: uppercase;
	font-weight: 300;
	opacity: 0.3;
}

.portfolio-description .cta{
	display: inline-block;
	margin-top: 2.5rem;
	font-size: 1.5rem;
	text-transform: uppercase;
	color:#AD974F;
	transition: all 650ms;
}

.portfolio-description .cta:hover{
	opacity: .7;
}

.view-more-div{
	margin-top: 7rem !important;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.view-more-btn{
	padding: 1rem 3rem 1rem 3rem;
	border-radius: 0;
	border: 1px solid #AD974F;
	color: white;
	background: transparent;
	font-weight: bold;
	transition: all 0.2s ease-in;
	cursor: pointer;
	outline: none;
}

.view-more-btn:hover{
	color: #AD974F;
	border: 1px solid white;
}
.icon-container-skill{
    height: 9rem;
    width: 9rem;
}

.technologies{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.front-end-tech,
.back-end-tech,
.other-tech{
    flex:1 1;
    border:2px solid white;
    margin: 1rem;
    text-align: center;
    padding: 1rem;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    max-width: 300px;
    border-top: 2px solid #AD974F;
    border-left:2px solid #AD974F;
    transition: all 650ms;
    font-size: 1.2rem;
    cursor: pointer;
}

.fa-wrench,
.fa-laptop-code,
.fa-desktop{
    color:#AD974F;
}

.front-end-tech:hover,
.back-end-tech:hover,
.other-tech:hover{
    border:2px solid white;
    border-bottom: 2px solid #AD974F;
    border-right:2px solid #AD974F;
}


.tech-list{
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.tech-list li {
    list-style-type: none;
    font-size: 1.5rem;
}
.timeline ul{
	border-left: 4px solid #AD974F;
	/* border-radius: .8rem; */
	margin:0 auto;
	position: relative;
	padding:5rem;
	list-style: none;
	text-align: left;
	width:70%;
}
.timeline h1{
	font-size: 2rem;
	text-transform: uppercase;
	font-weight: 300;
	margin-bottom: 1rem;
	opacity: .3;
}

.timeline .date{
	border-bottom: 1px solid rgba(255,255,255,0.1);
	margin-bottom: 1rem;
	position: relative;
}
.timeline .date:last-of-type{
	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: none;
}
.timeline .date::before,
.timeline .date::after {
	position: absolute;
	display: block;
	top:50%;
	transform: translateY(-50%);
}

.timeline .date::before{
	content: attr(data-date);
	left: -21rem;
	text-align: right;
	min-width: 12rem;
    font-size: 1.5rem;
    color:#AD974F;
}

.timeline .date::after{
	content: '';
	/* box-shadow: 0 0 0 4px white; */
	/* border-radius: 100%; */
	left:-6.4rem;
	height: .3rem;
	width:2.5rem;
    background-color:white;
    opacity: 0.6;
}

@media screen and (max-width: 768px) {
	/* .timeline ul{
		margin:0 0 0 auto;
	} */
	.timeline .date::before{
		left: -20rem;
	}
	/* .timeline .date::after{
		left:-6rem;
	} */
}

@media screen and (max-width: 600px) {
	.timeline ul{
		padding: 2rem;
		margin: 0 0 0 auto;
	}
	.timeline h2{
		font-size: 1.6rem;
	}

	.timeline .date::before{
		font-size: 1.4rem;
	}
	.timeline .date::after{
		left:-3.5rem;
	}
	.timeline .date::before{
		left: -17rem;
	}
}

form{
    width:70%;
    margin:0 auto;
}
form label {
    font-size:1.5rem;
}
input, select, textarea{
    width:100%;
    padding: 1rem;
    margin-bottom: 5px;
    border:none;
    outline-color:#AD974F; 
    background-color:lightgray;
}
input[type="submit"]{
    width:auto;
    width:initial;
    background-color:#AD974F;
    color:white;
    padding: 1rem 2rem;
    border-radius: .8rem;
    cursor: pointer;
    transition: background-color 650ms;
    border-radius: 0;
}

input[type="submit"]:hover{
    opacity: 0.6;
    background-color: #AD974F;
}
.alert-success{
    background-color: rgba(77, 175, 124, 1);
    text-align: center;
    width: 60%;
    margin-bottom: 1rem;
    margin: auto;
    padding: 1.2rem;
    font-size: 1.4rem;

}

.alert-danger{
    background-color: rgba(150, 40, 27, 1);
    text-align: center;
    width: 60%;
    margin-bottom: 1rem;
    margin: auto;
    padding: 1.2rem;
    font-size: 1.4rem;
}
.submit-button{
    margin-top: 1rem;
}
@media screen and (max-width: 600px) {
    form{
        width: 100%;
    }
}
.copyright{
    width:100%;
    text-align: center;
    padding:2rem 0;
    position: relative;
    border-top: 1px solid lightgray;
}
.up{
    position: absolute;
    width:5rem;
    height: 5rem;
    background-color: #eee;
    top:-2.5rem;
    right: 5rem;
    border-radius: 100%;
    display: flex;
    -webkit-animation: pulse 2s infinite;
            animation: pulse 2s infinite;
    cursor: pointer;
}

.up i{
    color: #16162d;
    font-size: 2rem;
    margin:auto;

}
.copyright p{
    font-size: 1.4rem;
}
/*End footer*/

/*Start animations*/
@-webkit-keyframes pulse{
    0%{
        box-shadow: 0 0 0 0 rgba(173, 151, 79, 0.99);
    }
    70%{
        box-shadow: 0 0 0 1rem rgba(173, 151, 79, 1);
    }
    100%{
        box-shadow: 0 0 0 0 rgba(173, 151, 79, 0);
    }
}
@keyframes pulse{
    0%{
        box-shadow: 0 0 0 0 rgba(173, 151, 79, 0.99);
    }
    70%{
        box-shadow: 0 0 0 1rem rgba(173, 151, 79, 1);
    }
    100%{
        box-shadow: 0 0 0 0 rgba(173, 151, 79, 0);
    }
}
/*End animations*/

@media screen and (max-width: 600px) {
    .up{
        right: 1rem;
    }
}
