.my-skills{
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.skill{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 2rem; */
    margin-right: 2rem;

}

.skill h1{
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 2rem -2px 2rem 0;
}

.icon-container {
    width: 14rem;
    height: 14rem;
    border: 3px solid white;
    background-color: transparent;
    margin-bottom: 2rem;
    display: flex;
    transform:rotate(45deg);
    justify-content: center;
}

.icon-container i {
    font-size: 5rem;
    margin:auto;
    transform: rotate(-45deg);
    color: #AD974F;
}

.icon-container h1{
    align-self: center;
    transform: rotate(-45deg);
    color: #AD974F;
    font-size: 2.3rem;
    text-transform: none;
}

@media screen and (max-width: 600px) {
    .my-skills{
        flex-direction: column;
    }
    .icon-container i{
        font-size: 3rem;
    }
    .icon-container{
        height: 8rem;
        width: 8rem;
    }
    .skill h1{
        font-size: 1.3rem;
        margin-left: 2rem;
    }
    .icon-container{
        margin-top: .5rem;
        margin-bottom: .5rem;
        margin-left: .8rem;
    }

    .icon-container h1{
        font-size: 1.6rem;
        margin-bottom: 1rem;
        margin-right: 3rem;
        padding-left: 2rem;
    }
   
}