@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
*{
    margin:0;
    padding:0;
	box-sizing: border-box;
}
html{
    font-size: 10px;
    color:#eee;
	font-family: 'Abel', sans-serif;
}
body{
    background-color:#1B1B1B;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    color: white;
	overflow-x: hidden;
}

section {
    padding: 5rem 0;
}
a {
    text-decoration: none;
    color: #eee;
}
p {
    font-size: 1.65rem;
    font-weight: 300;
}

img {
    width: 100%;
}

.container{
    width: 90%;
    max-width: 120rem;
    height: 100%;
    margin: 0 auto;
    position: relative;
}

.section-heading{
	text-align: center;
	margin-bottom:10rem;
}

.section-heading h1{
	font-size: 3.5rem;
	color:rgba(255,255,255,.3);
	text-transform: uppercase;
	font-weight: 300;
	position: relative;
	margin-bottom:1rem;
}

.section-heading h1::before,
.section-heading h1::after{
	content: '';
	position: absolute;
	bottom: -.5rem;
    background-color: rgba(255,255,255,0.75);
    left: 50%;
	transform: translateX(-50%);
}

.section-heading h1::before{
	width:10rem;
    height:3px;
    left: 50%;
	transform: translateX(-50%);
}
.section-heading h1::after{
	width:1.5rem;
	height: 1.5rem;
	/* border-radius: 100%; */
    bottom:-1rem;
    transform:rotate(45deg);
    margin-left: -.5rem;
    background: #AD974F;
}
.section-heading h6{
	font-size: 1.6rem;
	font-weight: 300;

}

.has-margin-right{
	margin-right: 5rem;
}


/* -------------------------media-------------------------------------------------------- */
@media screen and (max-width:1100px){
	html{
		overflow-x: hidden;
	}
}


@media screen and (max-width: 768px) {
	html{
		overflow-x: hidden;
	}
	.name-info h1{
		font-size:12rem;
	}
	.name-info h6{
		font-size: 1.7rem;
	}
	.nav-list{
		flex-direction: column;
	}
	li{
		margin:2rem 0;
	}
	.nav-link{
		font-size: 4.5rem;
	}
	.about-heading h1{
		font-size: 8rem;
	}
	.section-heading h1{
		font-size: 3rem;
	}
	.about-details .nav-list{
		flex-direction: initial;
	}
	.about-details li{
		margin:0 1.5rem;
	}

	.icon-container{
		width:7rem;
		height: 7rem;
	}
	.icon-container i{
		font-size: 4rem;
	}
	.timeline ul{
		margin:0 0 0 auto;
	}
	.timeline .date::befor{
		left: -20rem;
	}
	.timeline .date::after{
		left:-6rem;
	}
	}

	@media screen and (max-width: 600px) {
        
		html{
			overflow-x: hidden;
        }
        
		.menu-toggler{
			top:2rem;
			right: 2rem;
		}

		.name-info h1 {
			font-size: 8rem;
		}
		.name-info h6{
			font-size: 1.4rem;
		}
		.nav-link{
			font-size: 3.5rem;
		}
		.about .container{
			flex-direction:column;
		}
		.profile-img{
			margin:0 0 7rem 0;
		}

		.portfolio-item{
			flex-direction: column;
		}

		.portfolio-img{
			margin: 0 0 2rem 0;
			order:1;
		}
		.portfolio-description{
			margin:0;
			order:2;
		}

		.timeline p{
			font-size: 1.6rem;
		}

		.timeline .date::before{
			font-size: 1.4rem;
		}

		form{
			width:100%;
		}
		.up{
			right: 2rem;
		}
	}