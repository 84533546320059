.technologies{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.front-end-tech,
.back-end-tech,
.other-tech{
    flex:1;
    border:2px solid white;
    margin: 1rem;
    text-align: center;
    padding: 1rem;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    max-width: 300px;
    border-top: 2px solid #AD974F;
    border-left:2px solid #AD974F;
    transition: all 650ms;
    font-size: 1.2rem;
    cursor: pointer;
}

.fa-wrench,
.fa-laptop-code,
.fa-desktop{
    color:#AD974F;
}

.front-end-tech:hover,
.back-end-tech:hover,
.other-tech:hover{
    border:2px solid white;
    border-bottom: 2px solid #AD974F;
    border-right:2px solid #AD974F;
}


.tech-list{
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.tech-list li {
    list-style-type: none;
    font-size: 1.5rem;
}