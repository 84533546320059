.about .container {
    display: flex;
	align-items: center;
    justify-content: center;
}

.about-heading{
    text-align: center;
	text-transform: uppercase;
	line-height:0px;
	margin-bottom:6rem;
}

.about-heading h1{
    font-size: 10rem;
    opacity: .3;
}

.about-heading h6{
    font-size: 2rem;
    font-weight: 300;
}
.nav-list {
	list-style: none;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
    align-items: center; 
}

.nav-list-custom li {
    margin: 0 !important;
}
.profile-img{
    flex:1;
    margin-right: 5rem;
}


.about-details {
    flex: 1;
}

.social-media{
    margin-top: 5rem;
}

.social-media i {
    font-size: 5rem;
    transition: color 650ms;
}

.fa-facebook-square:hover{
	color:#4267b2;
}
.fa-cloud-download-alt:hover{
	color:#38a1f3;
}
.fa-github-square:hover{
    color:#333;
}

@media screen and (max-width: 768px) {
    .about-heading h1{
        font-size: 8rem;
	}
	.section-heading h1{
		font-size: 3rem;
    }
    .about-details .nav-list{
		flex-direction: initial;
    }
    .about-details i{
        font-size: 4rem;
	}
}

@media screen and (max-width: 600px) {
    .profile-img{
        margin:0 0 7rem 0;
    }
    .portfolio-item{
        flex-direction: column;
    }

}