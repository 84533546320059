.copyright{
    width:100%;
    text-align: center;
    padding:2rem 0;
    position: relative;
    border-top: 1px solid lightgray;
}
.up{
    position: absolute;
    width:5rem;
    height: 5rem;
    background-color: #eee;
    top:-2.5rem;
    right: 5rem;
    border-radius: 100%;
    display: flex;
    animation: pulse 2s infinite;
    cursor: pointer;
}

.up i{
    color: #16162d;
    font-size: 2rem;
    margin:auto;

}
.copyright p{
    font-size: 1.4rem;
}
/*End footer*/

/*Start animations*/
@keyframes pulse{
    0%{
        box-shadow: 0 0 0 0 rgba(173, 151, 79, 0.99);
    }
    70%{
        box-shadow: 0 0 0 1rem rgba(173, 151, 79, 1);
    }
    100%{
        box-shadow: 0 0 0 0 rgba(173, 151, 79, 0);
    }
}
/*End animations*/

@media screen and (max-width: 600px) {
    .up{
        right: 1rem;
    }
}